export default [
  {
    _name: "CSidebarNav",
    _children: [
      {
        _name: "CSidebarNavItem",
        name: "Users",
        to: "/users",
        icon: "cil-people"
      },
      {
        _name: "CSidebarNavItem",
        name: "Links",
        to: "/links",
        icon: "cil-credit-card"
      },

      {
        _name: "CSidebarNavTitle",
        _children: []
      },

      {
        _name: "CSidebarNavItem",
        name: "Deposits",
        to: "/deposits",
        icon: "cil-credit-card"
      },
      {
        _name: "CSidebarNavItem",
        name: "Numbers",
        to: "/numbers",
        icon: "cil-credit-card"
      }
    ]
  }
];
