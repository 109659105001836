import { render, staticRenderFns } from "./TheHeaderDropdownNotif.vue?vue&type=template&id=1bd10995&scoped=true&"
import script from "./TheHeaderDropdownNotif.vue?vue&type=script&lang=js&"
export * from "./TheHeaderDropdownNotif.vue?vue&type=script&lang=js&"
import style0 from "./TheHeaderDropdownNotif.vue?vue&type=style&index=0&id=1bd10995&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bd10995",
  null
  
)

export default component.exports